<template>
<santander/>
</template>

<script>
import santander from '@/components/santander.vue'
export default {
    components:{
        santander
    },
    data() {
        return {
           

            }
        }
 
}
</script>
